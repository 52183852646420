




































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import UserService from '@/services/UserService';

@Component
export default class ForgotPassword extends mixins(Toasts) {
  protected username = '';
  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected resetPassword(): void {
    this.setLoading(true);
    UserService.sendResetPasswordEmail(this.username)
      .then(() => {
        this.setLoading(false);
        this.showToast('Password Reset', 'An email was sent to your account.', 'success');
        this.$router.replace({ path: '/login' });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast(
            'Failed to reset',
            'Could not reset password. ' + backendError.response.data.message,
            'danger'
          );
        }
      });
  }
}
